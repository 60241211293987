/* eslint-disable no-prototype-builtins */
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular/standalone';
import { AlertOptions } from '@ionic/core';
import { BrainlaneModalException, ExceptionType } from '@mbp/mbp-brainlane-vouchers-api-client';
import { Action, ActionCreator, Store } from '@ngrx/store';
import { from, map, Observable } from 'rxjs';

import { ErrorModalComponent } from './error-modal/error-modal.component';

export type ErrorActionCreator<T extends string> = ActionCreator<
    T,
    (props: { error: string }) => {
        error: string;
    } & Action<T>
>;

@Injectable({ providedIn: 'root' })
export class ErrorService {
    private store = inject(Store);
    private modalController = inject(ModalController);
    private alertController = inject(AlertController);

    async showErrorModal(header: string, title: string | null, message: string, backText: string) {
        const topModal = await this.modalController.getTop();
        if (topModal) {
            await topModal.dismiss();
        }
        const modal = await this.modalController.create({
            component: ErrorModalComponent,
            componentProps: {
                header,
                title,
                message,
                backText,
            } satisfies Pick<ErrorModalComponent, 'header' | 'title' | 'message' | 'backText'>,
        });
        await modal.present();
        return modal;
    }

    handleError(originalAction: Action, failAction: ErrorActionCreator<string>, error: unknown): Observable<Action> {
        console.log(error);
        const canRetry = true;
        const errorMessage = this.getMessage(error);
        const action = failAction({ error: errorMessage });
        let observable: Observable<unknown>;
        if (
            error &&
            typeof error === 'object' &&
            error.hasOwnProperty('title') &&
            error.hasOwnProperty('header') &&
            error.hasOwnProperty('message')
        ) {
            const parsed = error as BrainlaneModalException;
            if (parsed.type === ExceptionType.MODAL) {
                observable = from(this.showErrorModal(parsed.header, parsed.title, parsed.message, 'Terug'));
            } else {
                observable = from(
                    this.showErrorAlert(canRetry, originalAction, parsed.title || 'Fout', parsed.message),
                );
            }
        } else {
            observable = from(this.showErrorAlert(canRetry, originalAction, 'Fout', errorMessage));
        }
        return observable.pipe(map(() => action));
    }

    getMessage(error: unknown) {
        if (error instanceof HttpErrorResponse) {
            error = error.error;
        }
        let errorMessage: string;
        if (typeof error === 'string') {
            errorMessage = error;
        } else {
            if (error && typeof error === 'object' && error.hasOwnProperty('message')) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                errorMessage = (error as any).message;
            } else {
                errorMessage = 'Er is een onbekende fout opgetreden. Probeer het later opnieuw.';
            }
        }
        return errorMessage;
    }

    private async showErrorAlert(canRetry: boolean, originalAction: Action, title: string, errorMessage: string) {
        const alertConfig: AlertOptions = {
            header: title,
            buttons: [
                {
                    text: 'Sluiten',
                    role: 'cancel',
                },
            ],
        };
        if (canRetry) {
            const retryBtn = {
                text: 'Opnieuw proberen',
                role: 'retry',
                handler: () => {
                    this.store.dispatch(originalAction as Action);
                },
            };
            alertConfig.buttons!.push(retryBtn);
        }
        alertConfig.message = errorMessage;
        const top = await this.alertController.getTop();
        await top?.dismiss();
        const dialog = await this.alertController.create(alertConfig);
        await dialog.present();
        return dialog;
    }
}
